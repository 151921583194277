import { AmbientLight, DirectionalLight, HemisphereLight } from 'three';

export function createLights() {
  const ambientLight = new AmbientLight('white', 1);

  const hemisphereLight = new HemisphereLight('white', 'white', 1);

  const leftLight = new DirectionalLight('white', 1);
  leftLight.position.set(-3, 3, 4);

  const rightLight = new DirectionalLight('white', 1);
  rightLight.position.set(-3, 3, 4);

  return { ambientLight, hemisphereLight, leftLight, rightLight };
}
