import { Group, Object3D } from 'three';

import { Knobs } from './Knobs.js';
import { SSRangeTop } from './SSRangeTop.js';

import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeParts } from '../parts/RangeParts.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { SharedParts } from '../../shared/SharedParts.js';
import {
  Burner,
  Cupboard,
  MeshName,
  RangeTop,
  OptionSpace,
  Region,
} from '../../shared/Enums.js';

export class Cluny1800 extends BaseRange {
  /** The range top option to the far left of range */
  #option1 = new Group();

  /** The range top option to the far right of range */
  #option2 = new Group();

  /**
   * A dynamic stainless steel range top that assembles the top based on the
   * base burners and selected options
   * @type {SSRangeTop}
   */
  #ssTop;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {SharedParts} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels
  ) {
    super(assets, materials, rangeParts, rangeTopOptions);

    this.classiqueTrim = rangeParts.cluny1800ClassiqueTrim();
    this.moderneTrim = rangeParts.cluny1800ModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);
    this.#loadModels();
    this.applyMaterials();
    this.#ssTop = new SSRangeTop(this.range, rangeTopOptions);

    // Configure optional burners for initial display
    this.#refreshRangeTop();

    const ssRangeTop = this.#ssTop.assembleSSRangeTop({
      fourFeux: false,
      option1: this.state.option1,
      option2: this.state.option2,
    });

    // Position the Lacanche logo
    const logo = sharedParts.logo.clone();
    logo.translateX(-0.1);

    this.range.add(
      this.baseBurners,
      this.#option1,
      this.#option2,
      ssRangeTop,
      logo
    );

    this.#loadFeatures();
  }

  /**
   * Change the range top style to classique or traditional
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] - Not used for Cluny 1800
   */
  changeRangeTop(rangeTop, assembleTop) {
    this.state.top = rangeTop;
    this.baseBurners.clear();

    switch (rangeTop) {
      case RangeTop.classique: {
        this.add11KBurners(-0.504, this.baseBurners);
        this.add18KBurner(-0.157, this.baseBurners);
        this.add15And5KBurners(0.194, this.baseBurners);
        break;
      }

      case RangeTop.traditional: {
        this.add11KBurners(-0.504, this.baseBurners);
        this.addTradPlate(-0.157, this.baseBurners);
        this.add15And5KBurners(0.194, this.baseBurners);
        break;
      }
    }
  }

  /**
   * Change the left cupboard to warming or storage
   * @param {string} type
   */
  changeLeftCupboard(type) {
    switch (type) {
      case Cupboard.warming:
        this.knobs.selectWarmingCupboard(Region.farLeft);
        break;

      case Cupboard.storage:
        this.knobs.selectStorageCupboard(Region.farLeft);
        break;
    }
  }

  /**
   * Change the right cupboard to warming or storage
   * @param {string} type
   */
  changeRightCupboard(type) {
    switch (type) {
      case Cupboard.warming:
        this.knobs.selectWarmingCupboard(Region.farRight);
        break;

      case Cupboard.storage:
        this.knobs.selectStorageCupboard(Region.farRight);
        break;
    }
  }

  /**
   * Change the one of the optional "burners" on the range top
   * @param {string} burnerOption
   * @param {string} optionSpace
   * @param {boolean} [assembleTop]
   */
  changeBurner(burnerOption, optionSpace, assembleTop = true) {
    const region = this.#knobRegion(optionSpace);
    let xOffset, burnerGroup;

    switch (optionSpace) {
      case OptionSpace.option1:
        xOffset = -0.855;
        burnerGroup = this.#option1;
        break;

      case OptionSpace.option2:
        xOffset = 0.54;
        burnerGroup = this.#option2;
        break;
    }

    this.state[optionSpace] = burnerOption;
    burnerGroup.clear();

    switch (burnerOption) {
      case Burner.two11kBurners:
        this.add11KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.two15kBurners:
        this.add15KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.one18kBurner:
        this.add18KBurner(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.flameGrill:
        this.addFlameGrill(xOffset, burnerGroup);
        this.knobs.addFlameGrill(region);
        break;

      case Burner.electricPlancha:
        this.addPlancha(xOffset, burnerGroup);
        this.knobs.addPlancha(region);
        break;

      case Burner.traditionalSimmerPlate:
        this.addTradPlate(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.multiCooker:
        this.addMultiCooker(xOffset, burnerGroup);
        this.knobs.addMultiCooker(region);
        break;

      case Burner.twoInductionRings:
        this.addInductionRings(xOffset, burnerGroup);
        this.knobs.addInductionRings(region);
        break;

      case Burner.stainlessSteelWorkstation:
        this.knobs.addSSWorkstation(region);
        break;

      default:
        this.knobs.addSSWorkstation(region);
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName(MeshName.rangeTop);
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({
        [optionSpace]: burnerOption,
      });
      this.range.add(newRangeTop);
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarMidLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarMidRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftCupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftCupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftOvenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightOvenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightCupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightCupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftDrawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.leftDrawerRightPull),
      this.classiqueTrim.getObjectByName(MeshName.rightDrawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.rightDrawerRightPull),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.leftCupboardDoor),
      this.range.getObjectByName(MeshName.rightCupboardDoor),
      this.range.getObjectByName(MeshName.leftOvenDoor),
      this.range.getObjectByName(MeshName.rightOvenDoor),
      this.range.getObjectByName(MeshName.leftStorageDrawer),
      this.range.getObjectByName(MeshName.rightStorageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeTopRim1800),
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.range.getObjectByName(MeshName.leftCooktopHingePlate),
      this.range.getObjectByName(MeshName.rightCooktopHingePlate),
    ];
  }

  /** Set all the optional burners to what they are supposed to be */
  #refreshRangeTop() {
    this.changeOption1(this.state.option1, false);
    this.changeOption2(this.state.option2, false);
  }

  /** Add all the Cluny 1800 meshes to the range Group */
  #loadModels() {
    // All meshes from the main Cluny 1800 model
    this.assets.cluny1800Data.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.cluny1800Parts());

    // Hide the range top rim. It is needed to assemble the range top, but is not needed after that
    this.range.getObjectByName(MeshName.rangeTopRim1800).visible = false;
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /**
   * Map the burner option space to the correct region of the control panel
   * @param {string} optionSpace
   * @returns {string}
   */
  #knobRegion(optionSpace) {
    switch (optionSpace) {
      case OptionSpace.option1:
        return Region.farLeft;

      case OptionSpace.option2:
        return Region.farRight;
    }
  }

  /** Set up the user interface to show all the range's configurable features */
  #loadFeatures() {
    this.features.farLeftOption = true;
    this.features.farRightOption = true;
    this.features.leftCupboard = true;
    this.features.rightCupboard = true;
  }
}
