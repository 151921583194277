import * as THREE from 'three';
import GUI from 'lil-gui';
import { MeshName } from '../shared/Enums.js';

export class HelperGUI {
  gui;
  material = new THREE.MeshStandardMaterial();

  #debugObject = {};

  constructor() {
    this.gui = new GUI();
    // this.gui.close();
  }

  addMaterial(range) {
    this.changeMaterial(range);
    range.changeMaterial(this.material);

    this.#debugObject.color = this.material.color.getHexString();
    this.#debugObject.roughness = this.material.roughness;
    this.#debugObject.metalness = this.material.metalness;

    this.guiColor = this.gui
      .addColor(this.#debugObject, 'color')
      .onChange(() => {
        this.material.color.set(this.#debugObject.color);
        range.changeMaterial(this.material);
      })
      .name('Range color');

    this.guiMetalness = this.gui
      .add(this.#debugObject, 'metalness')
      .min(0)
      .max(1)
      .step(0.01)
      .onChange(() => {
        this.material.metalness = this.#debugObject.metalness;
        range.changeMaterial(this.material);
      })
      .name('Metalness');

    this.guiRoughness = this.gui
      .add(this.#debugObject, 'roughness')
      .min(0)
      .max(1)
      .step(0.01)
      .onChange(() => {
        this.material.roughness = this.#debugObject.roughness;
        range.changeMaterial(this.material);
      })
      .name('Roughness');
  }

  changeMaterial(range) {
    const existingMaterial = range.range.getObjectByName(
      MeshName.controlPanel
    ).material;

    this.material = new THREE.MeshStandardMaterial({
      color: existingMaterial.color,
      metalness: existingMaterial.metalness,
      roughness: existingMaterial.roughness,
    });

    this.#debugObject.color = '#' + this.material.color.getHexString();
    this.#debugObject.roughness = this.material.roughness;
    this.#debugObject.metalness = this.material.metalness;

    if (this.guiColor) {
      this.guiColor.updateDisplay();
      this.guiMetalness.updateDisplay();
      this.guiRoughness.updateDisplay();
    }
  }

  addHemiLight(hemiLight) {
    const hemiLightFolder = this.gui.addFolder('Hemisphere Light');
    hemiLightFolder.add(hemiLight, 'intensity', 0, 5, 0.1);
  }

  addLeftLight(leftLight) {
    const leftLightFolder = this.gui.addFolder('Left Light');

    leftLightFolder.add(leftLight, 'intensity', 0, 5, 0.1);
    leftLightFolder.add(leftLight.position, 'x', -5, 0, 0.05).name('positionX');
    leftLightFolder.add(leftLight.position, 'y', 1, 5, 0.05).name('positionY');
    leftLightFolder.add(leftLight.position, 'z', -2, 8, 0.05).name('positionZ');
    leftLightFolder
      .add(leftLight.target.position, 'x', -3, 3, 0.1)
      .name('targetX');
    leftLightFolder
      .add(leftLight.target.position, 'y', -2, 2, 0.1)
      .name('targetY');
    leftLightFolder
      .add(leftLight.target.position, 'z', -6, 4, 0.1)
      .name('targetZ');
  }

  addRightLight(rightLight) {
    const rightLightFolder = this.gui.addFolder('Right Light');

    rightLightFolder.add(rightLight, 'intensity', 0, 5, 0.1);
    rightLightFolder
      .add(rightLight.position, 'x', 0, 5, 0.05)
      .name('positionX');
    rightLightFolder
      .add(rightLight.position, 'y', 1, 5, 0.05)
      .name('positionY');
    rightLightFolder
      .add(rightLight.position, 'z', -2, 8, 0.05)
      .name('positionZ');
    rightLightFolder
      .add(rightLight.target.position, 'x', -3, 3, 0.1)
      .name('targetX');
    rightLightFolder
      .add(rightLight.target.position, 'y', -2, 2, 0.1)
      .name('targetY');
    rightLightFolder
      .add(rightLight.target.position, 'z', -6, 4, 0.1)
      .name('targetZ');
  }
}
