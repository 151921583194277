import { WebGLRenderer, ACESFilmicToneMapping } from 'three';

export function createRenderer() {
  const renderer = new WebGLRenderer({ antialias: true });
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.shadowMap.enabled = true;

  return renderer;
}
