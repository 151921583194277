import { BufferGeometry, Group, Mesh, Object3D } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import { BaseSSRangeTop } from '../shared/BaseSSRangeTop';
import { RangeTopOptions } from '../shared/RangeTopOptions';

import { Burner, MeshName } from '../../shared/Enums';

export class SSRangeTop extends BaseSSRangeTop {
  /**
   * An outer rim geometry for a Chambertin range top
   * @type {BufferGeometry}
   */
  #topRimGeo;

  /** @type {Mesh} */
  #topRimMesh;

  /**
   * @typedef TopState
   * @type {object}
   * @property {boolean} [fourFeux] - is the range top 4-feux
   * @property {string} [option1] - the (only) range top option on the right
   */

  /**
   * Configured range top options
   * @type {TopState}
   */
  #state = {};

  /**
   * @param {Group} range
   * @param {RangeTopOptions} rangeTopOptions
   */
  constructor(range, rangeTopOptions) {
    super(rangeTopOptions, 0.55);

    this.#makeTopRimGeometry(range);
  }

  /**
   * The fully configured stainless steel range top - based on range top options
   * @param {TopState} newState
   * @returns {Mesh}
   */
  assembleSSRangeTop(newState) {
    this.#state = { ...this.#state, ...newState };

    const geometries = [
      this.#topRimGeo,
      this.#leftBaseSurface(),
      this.#spacerBaseToOption1(),
      this.#option1Surface(),
    ];
    if (!this.#state.fourFeux) {
      geometries.push(this.#midBaseSpacer(), this.#rightBaseSurface());
    }

    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);

    const rangeTop = new Mesh(mergedGeometry, this.#topRimMesh.material);
    rangeTop.name = 'range-top';

    return rangeTop;
  }

  /**
   * The pond geometry for 4-feux base burners or the 2 burner left base pond
   * geometry for classique and traditional tops.
   * @returns {BufferGeometry}
   */
  #leftBaseSurface() {
    if (this.#state.fourFeux) {
      return this.fontenay4BurnerPond(0);
    } else {
      return this.narrow2BurnerPond(0);
    }
  }

  /**
   * A range top spacer geometry in the "middle" of the base burners
   * @returns {BufferGeometry}
   */
  #midBaseSpacer() {
    return this.spacer22mm(0.26);
  }

  /**
   * A range top surface geometry for the right "half" of the base burners
   * @returns {BufferGeometry}
   */
  #rightBaseSurface() {
    return this.oneBurnerPond(0.282);
  }

  /**
   * A range top spacer geometry between the base burners and the option
   * @returns {BufferGeometry}
   */
  #spacerBaseToOption1() {
    if (this.#state.fourFeux) {
      return this.spacer16mm(0.678);
    } else {
      return this.spacer22mm(0.672);
    }
  }

  /**
   * A range top surface geometry for the fourth range top option
   * @returns {BufferGeometry}
   */
  #option1Surface() {
    const offset = 0.694;

    switch (this.#state.option1) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
      case Burner.multiCooker:
        return this.oneBurnerPond(offset);

      case Burner.flameGrill:
        return this.flameGrillPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }

  /**
   * Create a geometry for the outer rim of the range top
   * @param {Group} range
   */
  #makeTopRimGeometry(range) {
    // @ts-ignore
    this.#topRimMesh = range.getObjectByName(MeshName.rangeTopRim1100);

    this.#topRimGeo = this.#topRimMesh.geometry.clone();
    this.#topRimGeo.applyQuaternion(this.#topRimMesh.quaternion);
    this.#topRimGeo.translate(
      this.#topRimMesh.position.x,
      this.#topRimMesh.position.y,
      this.#topRimMesh.position.z
    );
  }
}
