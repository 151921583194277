/**
 * Optional burners
 * @readonly
 * @enum {string}
 */
const Burner = {
  two11kBurners: 'LAG020UR',
  two15kBurners: 'LAG024UR',
  one18kBurner: 'LAG010UR',
  flameGrill: 'LAG010CK',
  electricPlancha: 'LAE010TPK',
  traditionalSimmerPlate: 'LAG010CF',
  multiCooker: 'LAE010CVA',
  twoInductionRings: 'LAE020I',
  stainlessSteelWorkstation: 'SS',
  gasBurner: 'burner',
};
Object.freeze(Burner);

/**
 * Range and hood colors
 * @readonly
 * @enum {string}
 */
const Color = {
  anthracite: 'anthracite',
  ardoise: 'ardoise',
  armor: 'armor',
  britishRacingGreen: 'britishRacingGreen',
  burgundyRed: 'burgundyRed',
  chantilly: 'chantilly',
  chocolat: 'chocolat',
  coralBlue: 'coralBlue',
  delftBlue: 'delftBlue',
  englishCreme: 'englishCreme',
  faience: 'faience',
  frangipane: 'frangipane',
  frenchBlue: 'frenchBlue',
  griotte: 'griotte',
  ivory: 'ivory',
  mandarine: 'mandarine',
  marronGlace: 'marronGlace',
  matteBlack: 'matteBlack',
  olive: 'olive',
  plum: 'plum',
  portugueseBlue: 'portugueseBlue',
  provenceYellow: 'provenceYellow',
  roseQuartz: 'roseQuartz',
  shinyBlack: 'shinyBlack',
  stainlessSteel: 'stainlessSteel',
  terracotta: 'terracotta',
  tilleul: 'tilleul',
  vertSilice: 'vertSilice',
  vertSologne: 'vertSologne',
  white: 'white',
};
Object.freeze(Color);

/**
 * Cupboard types
 * @readonly
 * @enum {string}
 */
const Cupboard = {
  warming: 'warming',
  storage: 'storage',
};
Object.freeze(Cupboard);

/**
 * Range lines
 * @readonly
 * @enum {string}
 */
const Line = {
  classique: 'classique',
  moderne: 'moderne',
};
Object.freeze(Line);

/**
 * Three.js mesh names for important 3D objects
 * @readonly
 * @enum {string}
 */
const MeshName = {
  aerationVentBlack: 'Aeration_Vent_Black',
  backSpacer: 'Back_Spacer',
  backSpacerBlack: 'Back_Spacer_Black',
  classiqueAerationVent: 'Aeration_Vent_Classique',
  classiqueCupboardDoorBar: 'Cupboard_Door_Bar_Classique',
  classiqueClunyOvenDoorBar: 'Oven_Door_Bar_Classique_Cluny',
  classiqueSullyOvenDoorBar: 'Oven_Door_Bar_Classique_Sully',
  classiqueVolnayOvenDoorBar: 'Oven_Door_Bar_Classique_Volnay',
  classiqueKnob: 'Knob_Classique',
  clunyOvenDoor: 'Oven_Door_Cluny',
  clunyStorageDrawer: 'Storage_Drawer_Cluny',
  controlPanel: 'Control_Panel',
  cupboardDoor: 'Cupboard_Door',
  cupboardDoorBar: 'Cupboard_Door_Bar',
  cupboardBarLeftSupport: 'Cupboard_Bar_Support_Left',
  cupboardBarRightSupport: 'Cupboard_Bar_Support_Right',
  cupboardVentCover: 'Cupboard_Vent_Cover',
  doorBarLeftSupport: 'Door_Bar_Support_Left',
  doorBarRightSupport: 'Door_Bar_Support_Right',
  drawerLeftPull: 'Drawer_Pull_Left',
  drawerPull: 'Drawer_Pull',
  drawerRightPull: 'Drawer_Pull_Right',
  electricOvenBackPanel: 'Back_Panel_Electric_Oven',
  gasOvenBackPanel: 'Back_Panel_Gas_Oven',
  gasOvenChimneyBackPanel: 'Back_Panel_Gas_Oven_Chimney',
  leftCooktopHingePlate: 'Cooktop_Hinge_Plate_Left',
  leftCupboardDoor: 'Left_Cupboard_Door',
  leftCupboardBarLeftSupport: 'Left_Cupboard_Bar_Support_Left',
  leftCupboardBarRightSupport: 'Left_Cupboard_Bar_Support_Right',
  leftCupboardVentCover: 'Left_Cupboard_Vent_Cover',
  leftDrawerLeftPull: 'Left_Drawer_Pull_Left',
  leftDrawerRightPull: 'Left_Drawer_Pull_Right',
  leftOvenDoor: 'Left_Oven_Door',
  leftOvenDoorBarLeftSupport: 'Left_Oven_Door_Bar_Support_Left',
  leftOvenDoorBarRightSupport: 'Left_Oven_Door_Bar_Support_Right',
  leftOvenMiniVentCover: 'Left_Oven_Mini_Vent_Cover',
  leftOvenVentCover: 'Left_Oven_Vent_Cover',
  leftStorageDrawer: 'Left_Storage_Drawer',
  moderneAerationVent: 'Aeration_Vent_Moderne',
  moderneClunyDrawerBottomLip: 'Drawer_Bottom_Lip_Moderne_Cluny',
  moderneClunyOvenDoorBar: 'Oven_Door_Bar_Moderne_Cluny',
  moderneCupboardDoorBar: 'Cupboard_Door_Bar_Moderne',
  moderneDoorBarSupport: 'Door_Bar_Support_Moderne',
  moderneSullyDrawerBottomLip: 'Drawer_Bottom_Lip_Moderne_Sully',
  moderneSullyOvenDoorBar: 'Oven_Door_Bar_Moderne_Sully',
  moderneVolnayCupboardDoorBar: 'Cupboard_Door_Bar_Moderne_Volnay',
  moderneVolnayDrawerBottomLip: 'Drawer_Bottom_Lip_Moderne_Volnay',
  moderneVolnayOvenDoorBar: 'Oven_Door_Bar_Moderne_Volnay',
  moderneKnob: 'Knob_Moderne',
  ovenDoor: 'Oven_Door',
  ovenDoorBar: 'Oven_Door_Bar',
  ovenDoorBarLeftSupport: 'Oven_Door_Bar_Support_Left',
  ovenDoorBarRightSupport: 'Oven_Door_Bar_Support_Right',
  ovenVentCover: 'Oven_Vent_Cover',
  petiteOvenDoor: 'Petite_Oven_Door',
  petiteOvenDoorBar: 'Petite_Oven_Door_Bar',
  petiteOvenDoorBarLeftSupport: 'Petite_Oven_Door_Bar_Support_Left',
  petiteOvenDoorBarRightSupport: 'Petite_Oven_Door_Bar_Support_Right',
  rangeBackPanel: 'Back_Panel_Range',
  rangeBase: 'Range_Base',
  rangeSides: 'Range_Sides',
  rangeTop: 'range-top',
  rangeTop700: '700_Range_Top',
  rangeTop900: '900_Range_Top',
  rangeTop1000: '1000_Range_Top',
  rangeTopRim1100: '1100_Range_Top_Rim',
  rangeTopRim1400: '1400_Range_Top_Rim',
  rangeTopRim1500: '1500_Range_Top_Rim',
  rangeTopRim1800: '1800_Range_Top_Rim',
  rangeTopRim2200: '2200_Range_Top_Rim',
  rightCooktopHingePlate: 'Cooktop_Hinge_Plate_Right',
  rightCupboardDoor: 'Right_Cupboard_Door',
  rightCupboardBarLeftSupport: 'Right_Cupboard_Bar_Support_Left',
  rightCupboardBarRightSupport: 'Right_Cupboard_Bar_Support_Right',
  rightCupboardVentCover: 'Right_Cupboard_Vent_Cover',
  rightDrawerLeftPull: 'Right_Drawer_Pull_Left',
  rightDrawerRightPull: 'Right_Drawer_Pull_Right',
  rightOvenDoor: 'Right_Oven_Door',
  rightOvenDoorBarLeftSupport: 'Right_Oven_Door_Bar_Support_Left',
  rightOvenDoorBarRightSupport: 'Right_Oven_Door_Bar_Support_Right',
  rightOvenMiniVentCover: 'Right_Oven_Mini_Vent_Cover',
  rightOvenVentCover: 'Right_Oven_Vent_Cover',
  rightStorageDrawer: 'Right_Storage_Drawer',
  storageDrawer: 'Storage_Drawer',
  sullyOvenDoor: 'Oven_Door_Sully',
  sullyStorageDrawer: 'Storage_Drawer_Sully',
  toeKick: 'Toe_Kick',
  towelBar: 'Towel_Bar',
  towelBarCenterSupport: 'Towel_Bar_Support_Center',
  towelBarleftSupport: 'Towel_Bar_Support_Left',
  towelBarMidLeftSupport: 'Towel_Bar_Support_Mid_Left',
  towelBarMidRightSupport: 'Towel_Bar_Support_Mid_Right',
  towelBarRightSupport: 'Towel_Bar_Support_Right',
  vctBackPanel: 'Back_Panel_VCT',
  vctBackPanelTop: 'Back_Panel_VCT_Top',
  volnayCupboardDoor: 'Cupboard_Door_Volnay',
  volnayOvenDoor: 'Oven_Door_Volnay',
  volnayStorageDrawer: 'Storage_Drawer_Volnay',
};
Object.freeze(MeshName);

/**
 * Spaces for optional burners
 * @readonly
 * @enum {string}
 */
const OptionSpace = {
  option1: 'option1',
  option2: 'option2',
  option3: 'option3',
  option4: 'option4',
};
Object.freeze(OptionSpace);

/**
 * Oven types
 * @readonly
 * @enum {string}
 */
const Oven = {
  convection: 'convection',
  convectionAlt: 'convection-alt',
  electric: 'electric',
  gas: 'gas',
  leftVertConv: 'left-vert-conv',
  petite: 'petite',
  rightVertConv: 'right-vert-conv',
};
Object.freeze(Oven);

/**
 * Range top styles
 * @readonly
 * @enum {string}
 */
const RangeTop = {
  classique: 'classique',
  traditional: 'traditional',
  fourFeux: '4-feux',
  classiqueRev: 'classique-rev',
};
Object.freeze(RangeTop);

/**
 * Range regions
 * @readonly
 * @enum {string}
 */
const Region = {
  farLeft: 'farLeft',
  left: 'left',
  center: 'center',
  right: 'right',
  farRight: 'farRight',
};

/**
 * Back Spacer
 * @readonly
 * @enum {string}
 */
const Spacer = {
  raised: 'raised',
  flush: 'flush',
};
Object.freeze(Spacer);

/**
 * Range trims
 * @readonly
 * @enum {string}
 */
const Trim = {
  brass: 'brass',
  brushedSS: 'brushedSS',
  chrome: 'chrome',
  nickel: 'nickel',
};
Object.freeze(Trim);

export {
  Burner,
  Color,
  Cupboard,
  Line,
  MeshName,
  OptionSpace,
  Oven,
  RangeTop,
  Region,
  Spacer,
  Trim,
};
