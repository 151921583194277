import { Group, Object3D } from 'three';

import { Knobs } from './Knobs.js';

import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeParts } from '../parts/RangeParts.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { SharedParts } from '../../shared/SharedParts.js';
import { MeshName, RangeTop } from '../../shared/Enums.js';

export class Beaune extends BaseRange {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {SharedParts} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels
  ) {
    super(assets, materials, rangeParts, rangeTopOptions);

    this.classiqueTrim = rangeParts.beauneClassiqueTrim();
    this.moderneTrim = rangeParts.beauneModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);
    this.#loadModels();
    this.applyMaterials();

    const logo = this.#createLogo(sharedParts);

    this.range.add(this.baseBurners, logo);

    this.#setUIFeatures();
  }

  /**
   * Change the range top style to classique or classique-reverse
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] Ignored parameter (used for other ranges)
   */
  changeRangeTop(rangeTop, assembleTop = true) {
    this.state.top = rangeTop;
    this.baseBurners.clear();

    switch (rangeTop) {
      case RangeTop.classique:
        this.add5And11KBurners(-0.445, this.baseBurners);
        this.addGrateRightSpacerTabs(-0.445, this.baseBurners);
        this.add15And11KBurners(-0.157, this.baseBurners);
        this.add5And11KBurners(0.131, this.baseBurners);
        this.addGrateLeftSpacerTabs(0.131, this.baseBurners);
        break;

      case RangeTop.classiqueRev:
        this.add11And5KBurners(-0.445, this.baseBurners);
        this.addGrateRightSpacerTabs(-0.445, this.baseBurners);
        this.add15And11KBurners(-0.157, this.baseBurners);
        this.add11And5KBurners(0.131, this.baseBurners);
        this.addGrateLeftSpacerTabs(0.131, this.baseBurners);
        break;
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.cupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.cupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.drawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.drawerRightPull),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.ovenDoor),
      this.range.getObjectByName(MeshName.cupboardDoor),
      this.range.getObjectByName(MeshName.storageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeTop900),
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeBackPanel),
      this.range.getObjectByName(MeshName.gasOvenBackPanel),
      this.range.getObjectByName(MeshName.vctBackPanel),
      this.range.getObjectByName(MeshName.vctBackPanelTop),
      this.range.getObjectByName(MeshName.leftCooktopHingePlate),
      this.range.getObjectByName(MeshName.rightCooktopHingePlate),
    ];
  }

  /** Add all the Beaune meshes to the range Group  */
  #loadModels() {
    this.assets.beauneData.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.beauneParts());
  }

  /**
   * Create the Lacanche logo to show on the oven door
   * @param {SharedParts} sharedParts
   * @returns {Group}
   */
  #createLogo(sharedParts) {
    const logo = sharedParts.logo.clone();
    logo.position.x = -0.535;

    return logo;
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /** Set up the user interface to show all the range's configurable features */
  #setUIFeatures() {
    this.features.traditional = false;
    this.features.classiqueRev = true;
  }
}
