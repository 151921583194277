import { Mesh, MeshStandardMaterial, Object3D } from 'three';

import { Colors } from './Colors.js';
import { Metals } from './Metals.js';
import { AssetLoader } from './AssetLoader.js';

export class Materials {
  #colors;
  #metals;

  /** @param {AssetLoader} assets */
  constructor(assets) {
    this.#colors = new Colors(assets);
    this.#metals = new Metals(assets);
  }

  /**
   * Set all the trim parts to the selected trim
   * @param {string} trim
   * @param {Object3D[]} trimParts
   */
  changeTrim(trim, trimParts) {
    const trimMaterial = this.#metals[trim + 'Trim'] ?? this.#metals.brassTrim;

    trimParts.forEach(
      (/** @type {Mesh} */ part) => (part.material = trimMaterial)
    );
  }

  /**
   * Set all the porcelain enamal parts to the selected color
   * @param {string} color
   * @param {Object3D[]} colorParts
   */
  changeColor(color, colorParts) {
    const colorMaterial = this.#colors[color] ?? this.#colors.burgundyRed;
    this.changeMaterial(colorMaterial, colorParts);
  }

  /**
   * Set the selected meshes to the selected material
   * @param {MeshStandardMaterial} material
   * @param {Object3D[]} parts
   */
  changeMaterial(material, parts) {
    parts.forEach((/** @type {Mesh} */ part) => (part.material = material));
  }

  changeKnobDialColor(color, line, dials) {
    let colorMaterial;
    const brownDials = [
      'chantilly',
      'delftBlue',
      'englishCreme',
      'faience',
      'ivory',
      'mandarine',
      'provenceYellow',
      'roseQuartz',
      'tilleul',
      'vertSilice',
      'vertSologne',
      'white',
    ];

    if (line === 'moderne') {
      colorMaterial = this.#colors.blackBody;
    } else if (brownDials.includes(color)) {
      colorMaterial = this.#colors.brownDial;
    } else {
      colorMaterial = this.#colors.beigeDial;
    }

    dials.forEach((dial) => {
      if (dial.isMesh) dial.material = colorMaterial;
    });
  }

  applyStainlessSteelMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.stainlessSteel));
  }

  applyGalvanizedSteelMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.galvanizedSteel));
  }

  applyBrassMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.brassTrim));
  }

  applyChromeMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.chromeTrim));
  }

  applyBrushedSSMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.brushedSSTrim));
  }

  applyEnamelCastIronMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.enamelCastIron));
  }

  applyMatteBlackMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.matteBlack));
  }

  applyBlackBodyMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.blackBody));
  }

  applyBurgundyRedMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.burgundyRed));
  }

  applyWhiteMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.white));
  }

  applyBurnerMaterials(burners) {
    burners.forEach((burner) => {
      burner.cap.material = this.#metals.brassCap;
      burner.bowl.material = this.#metals.castAluminum;
      burner.blackRing.material = this.#colors.blackBody;
    });
  }

  applyThermocoupleMaterials(thermocouple) {
    thermocouple.inner.material = this.#metals.brassCap;
    thermocouple.outer.material = this.#colors.blackBody;
  }

  applyIgnitorMaterials(ignitor) {
    ignitor.inner.material = this.#metals.brassCap;
  }

  applyAmberLightMaterial(light) {
    light.material = this.#colors.amberLight;
  }

  applyRedLightMaterial(light) {
    light.material = this.#colors.redLight;
  }

  applyLacancheLogoMaterial(logoPlate) {
    logoPlate.material = this.#metals.logoPlate;
  }
}
