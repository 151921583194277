import { Group, Mesh, Object3D } from 'three';

import { Knobs } from './Knobs.js';
// The Changy 1400 (left) and Cluny 1400 (left) have identical range tops
import { SSRangeTop } from '../cluny1400left/SSRangeTop.js';

import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeParts } from '../parts/RangeParts.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { SharedParts } from '../../shared/SharedParts.js';
import {
  Burner,
  Cupboard,
  MeshName,
  RangeTop,
  Region,
} from '../../shared/Enums.js';

export class Chagny1400Left extends BaseRange {
  /** The range top option to the far left of range */
  #option1 = new Group();

  /**
   * A dynamic stainless steel range top that assembles the top based on the
   * base burners and the selected option
   * @type {SSRangeTop}
   */
  #ssTop;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {SharedParts} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels
  ) {
    super(assets, materials, rangeParts, rangeTopOptions);

    this.classiqueTrim = rangeParts.chagny1400LeftClassiqueTrim();
    this.moderneTrim = rangeParts.chagny1400LeftModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);
    this.#loadModels();
    this.applyMaterials();

    const ssRangeTop = this.#createRangeTop(rangeTopOptions);
    const logo = this.#createLogo(sharedParts);

    this.range.add(this.baseBurners, this.#option1, ssRangeTop, logo);

    this.#loadFeatures();
  }

  /**
   * Change the range top style to classique or traditional
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] - Not used for Chagny 1400
   */
  changeRangeTop(rangeTop, assembleTop) {
    this.state.top = rangeTop;
    this.baseBurners.clear();

    switch (rangeTop) {
      case RangeTop.classique: {
        this.add11KBurners(-0.3, this.baseBurners);
        this.add18KBurner(0.051, this.baseBurners);
        this.add15And5KBurners(0.406, this.baseBurners);
        break;
      }

      case RangeTop.traditional: {
        this.add11KBurners(-0.3, this.baseBurners);
        this.addTradPlate(0.051, this.baseBurners);
        this.add15And5KBurners(0.406, this.baseBurners);
        break;
      }
    }
  }

  /**
   * Change the left cupboard to warming or storage
   * @param {string} type
   */
  changeLeftCupboard(type) {
    switch (type) {
      case Cupboard.warming:
        this.knobs.selectWarmingCupboard(Region.farLeft);
        break;

      case Cupboard.storage:
        this.knobs.selectStorageCupboard(Region.farLeft);
        break;
    }
  }

  /**
   * Change the optional "burner" on the range top
   * @param {string} burnerOption
   * @param {string} optionSpace - ignored since there is only one option space
   * @param {boolean} [assembleTop]
   */
  changeBurner(burnerOption, optionSpace, assembleTop = true) {
    const region = Region.farLeft;
    const xOffset = -0.655;
    const burnerGroup = this.#option1;

    this.state.option1 = burnerOption;
    burnerGroup.clear();

    switch (burnerOption) {
      case Burner.two11kBurners:
        this.add11KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.two15kBurners:
        this.add15KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.one18kBurner:
        this.add18KBurner(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.flameGrill:
        this.addFlameGrill(xOffset, burnerGroup);
        this.knobs.addFlameGrill(region);
        break;

      case Burner.electricPlancha:
        this.addPlancha(xOffset, burnerGroup);
        this.knobs.addPlancha(region);
        break;

      case Burner.traditionalSimmerPlate:
        this.addTradPlate(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.multiCooker:
        this.addMultiCooker(xOffset, burnerGroup);
        this.knobs.addMultiCooker(region);
        break;

      case Burner.twoInductionRings:
        this.addInductionRings(xOffset, burnerGroup);
        this.knobs.addInductionRings(region);
        break;

      case Burner.stainlessSteelWorkstation:
        this.knobs.addSSWorkstation(region);
        break;

      default:
        this.knobs.addSSWorkstation(region);
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName(MeshName.rangeTop);
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({
        option1: burnerOption,
      });
      this.range.add(newRangeTop);
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarMidLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.cupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.cupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.petiteOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(
        MeshName.petiteOvenDoorBarRightSupport
      ),
      this.classiqueTrim.getObjectByName(MeshName.leftOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftOvenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightOvenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.drawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.drawerRightPull),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.cupboardDoor),
      this.range.getObjectByName(MeshName.petiteOvenDoor),
      this.range.getObjectByName(MeshName.leftOvenDoor),
      this.range.getObjectByName(MeshName.rightOvenDoor),
      this.range.getObjectByName(MeshName.storageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeTopRim1400),
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeBackPanel),
      this.range.getObjectByName(MeshName.electricOvenBackPanel),
      this.range.getObjectByName(MeshName.gasOvenBackPanel),
      this.range.getObjectByName(MeshName.vctBackPanel),
      this.range.getObjectByName(MeshName.vctBackPanelTop),
      this.range.getObjectByName(MeshName.leftCooktopHingePlate),
      this.range.getObjectByName(MeshName.rightCooktopHingePlate),
    ];
  }

  /** Add all the Chagny 1400 (left) meshes to the range Group */
  #loadModels() {
    this.assets.chagny1400LeftData.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.chagny1400LeftParts());

    // Hide the range top rim. It is only needed to assemble the range top
    this.range.getObjectByName(MeshName.rangeTopRim1400).visible = false;
  }

  /**
   * Create a properly configured stainless steel range top
   * @param {RangeTopOptions} rangeTopOptions
   * @returns {Mesh}
   */
  #createRangeTop(rangeTopOptions) {
    this.#ssTop = new SSRangeTop(this.range, rangeTopOptions);

    return this.#ssTop.assembleSSRangeTop({
      option1: this.state.option1,
    });
  }

  /**
   * Create the Lacanche logo to show on the oven door
   * @param {SharedParts} sharedParts
   * @returns {Group}
   */
  #createLogo(sharedParts) {
    const logo = sharedParts.logo.clone();
    logo.translateX(0.1);

    return logo;
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /** Set up the user interface to show all the range's configurable features */
  #loadFeatures() {
    this.features.farLeftOption = true;
    this.features.leftCupboard = true;
  }
}
